import { Button, Divider, Icon } from '@/components'

import styles from './Actions.module.scss'

export const Actions = ({ type, onClick }) => {
  const REPORT_TEMPLATES = {
    define: `${baseUrl}/documents/Define_Positivo_Risco-Negocio-Limite.pdf`, // eslint-disable-line no-undef
    acerta: `${baseUrl}/documents/Acerta_Positivo_Essencial-Mais-Completo.pdf` // eslint-disable-line no-undef
  }

  return (
    <div className={styles.actions}>
      <Button
        variant="link"
        id="businessSolutions-seeReport-button"
        onClick={() => onClick(type)}
      >
        <Icon size="xs" color="primary" name="equifax-file-analysis-2" />
        Ver detalhes do relatório
      </Button>

      <Divider customClass={styles.actions__divider} />

      <Button
        variant="link"
        tagName="a"
        target="_blank"
        rel="noreferrer"
        id="businessSolutions-downloadReport-button"
        href={REPORT_TEMPLATES[type]}
        download={REPORT_TEMPLATES[type]}
        onClick={() => onClick()}
      >
        <Icon size="xs" color="primary" name="equifax-file-download" />
        Baixar modelo de relatório
      </Button>
    </div>
  )
}
