import { useEffect, useRef, useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import {
  HOME_FILTERS,
  HOME_SERVICES,
  POSTPAID_FILTERS,
  POSTPAID_SERVICES
} from './constants'

export const useSolutions = ({ variant }) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0)
  const [isServiceAnimated, setIsServiceAnimated] = useState(false)
  const [visibleDetailsModal, setVisibleDetailsModal] = useState()

  const intervalRef = useRef(null)
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  const services = variant === 'home' ? HOME_SERVICES : POSTPAID_SERVICES
  const filters = variant === 'home' ? HOME_FILTERS : POSTPAID_FILTERS

  const visibleService = services[activeFilterIndex]

  useEffect(() => {
    if (!isMobile) {
      let start = 0

      intervalRef.current = setInterval(() => {
        start += 1

        onSelectFilter(start)

        if (start + 1 === services.length) {
          clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }, 8000)

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [isMobile])

  const stopAutomaticFilterSelection = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const onClickAction = (type) => {
    type && setVisibleDetailsModal(type)
    stopAutomaticFilterSelection()
  }

  const onSelectFilter = (index, shouldClearInterval) => {
    shouldClearInterval && stopAutomaticFilterSelection()
    setIsServiceAnimated(true)

    setTimeout(() => {
      setActiveFilterIndex(index)
      setIsServiceAnimated(false)
    }, 500)
  }

  return {
    filters,
    activeFilterIndex,
    visibleDetailsModal,
    visibleService,
    isMobile,
    isServiceAnimated,
    onClickAction,
    onSelectFilter,
    setVisibleDetailsModal
  }
}
