import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'

import { useProposalContext } from '@/context/ProposalContext'

import { useStepHandler } from '@/hooks/useStepHandler'

import { API_FLOW_LABEL, SOFTLEAD_LABEL } from '@/constants'
import { SELF_CONSULT_FLOW_DATA } from '@/constants/steps'

export const useConsultation = () => {
  const { createSoftlead } = useStepHandler('softlead')
  const { isLoading } = useProposalContext()

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    const parsedSoftleadData =
      JSON.parse(sessionStorage.getItem(SOFTLEAD_LABEL)) || {}

    reset(parsedSoftleadData)
  }, [])

  const onSubmit = async ({ cnpj }) => {
    sessionStorage.setItem(
      API_FLOW_LABEL,
      JSON.stringify(SELF_CONSULT_FLOW_DATA)
    )

    const payload = { cnpj: removeSpecialChars(cnpj) }

    await createSoftlead({ payload, flowData: SELF_CONSULT_FLOW_DATA })
  }

  return {
    control,
    isValid,
    isLoading,
    onSubmit,
    handleSubmit
  }
}
