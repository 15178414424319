import { Container, Proposal } from '@/components'

import { HERO_TEXTS } from './constants'

import styles from './Hero.module.scss'

const HeroTexts = ({ variant }) => {
  return (
    <div className={styles.hero__content}>
      <h1 className={styles.hero__title}>{HERO_TEXTS[variant].title}</h1>
      <h2 className={styles.hero__subtitle}>{HERO_TEXTS[variant].subtitle}</h2>
    </div>
  )
}

export const Hero = ({ variant }) => {
  return (
    <section className={`${styles.hero} ${styles[`hero--${variant}`]}`}>
      <Container classname={styles.hero__wrapper}>
        <HeroTexts variant={variant} />

        <div className={styles.hero__proposal}>
          <Proposal />
        </div>
      </Container>
    </section>
  )
}
