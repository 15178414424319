import {
  Container,
  CustomPlan,
  Icon,
  If,
  Loader,
  Modal,
  PaymentExample,
  Plan,
  SliderController,
  Subtitle,
  Tag,
  Title
} from '@/components'

import { RECOMMENDED_PLAN_PRICE } from '@/constants/plans'

import { usePlansSection } from './usePlansSection'

import styles from './styles.module.scss'

export const Plans = () => {
  const {
    plans,
    listRef,
    itemsRef,
    postpaidPlans,
    expandedPlans,
    visibleElementIndex,
    isMobile,
    isPaymentDetailsOpen,
    renderedContentType,
    setExpandedPlans,
    setIsPaymentDetailsOpen,
    onScroll,
    onClickController
  } = usePlansSection()

  return (
    <section id="planos" className={styles.plans}>
      <Container>
        <Tag bgColor="white" text="Planos Pós-pago" />

        <Title
          text={
            <>
              Escolha o <b>melhor plano para o seu négócio</b>
            </>
          }
          customClass={styles.plans__title}
        />

        <Subtitle
          variant="small"
          text="Todos os planos são sujeitos a análise e incluem os relatórios básico, intermediário e completo de Pessoa Física e Pessoa Jurídica."
          customClass={styles.plans__subtitle}
        />

        <If condition={plans.length === 0}>
          <Loader customClass={styles.plans__loader} />
        </If>

        <If condition={plans.length > 0}>
          <SliderController
            size="medium"
            variant="tertiary"
            section="plans"
            length={isMobile ? 5 : 3}
            visibleElementIndex={visibleElementIndex}
            onClick={onClickController}
            customClass={styles.plans__controllers}
          />

          <div ref={listRef} className={styles.plans__list} onScroll={onScroll}>
            {plans.map((plan, index) => (
              <Plan
                hasCTA
                plan={plan}
                key={`plans-${plan.plan_price}`}
                customClass={styles.plans__plan}
                expandedPlans={expandedPlans}
                selectedPlan={RECOMMENDED_PLAN_PRICE}
                setExpandedPlans={setExpandedPlans}
                openPaymentDetails={() => setIsPaymentDetailsOpen(true)}
                reference={(ref) => (itemsRef.current[index] = ref)}
              />
            ))}

            {renderedContentType === 'default' && (
              <CustomPlan
                variant="horizontalList"
                customClass={styles.plans__plan}
                reference={(ref) =>
                  (itemsRef.current[postpaidPlans.length] = ref)
                }
              />
            )}
          </div>
        </If>

        <div className={styles.plans__disclaimer}>
          <Icon name="equifax-info" color="red" size="xs" />
          <small>
            A assinatura de qualquer plano possui renovação automática
          </small>
        </div>
      </Container>

      <If condition={isPaymentDetailsOpen}>
        <Modal
          size="medium"
          section="plans"
          onClose={() => setIsPaymentDetailsOpen(false)}
        >
          <PaymentExample />
        </Modal>
      </If>
    </section>
  )
}
