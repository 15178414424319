import Image from 'next/image'
import dynamic from 'next/dynamic'

import {
  Button,
  ButtonWhatsapp,
  Container,
  Filters,
  Tag,
  Title
} from '@/components'

import useClickWithScroll from '@equifax-ui/hooks/useClickWithScroll'

import PortalImg from '/public/images/portal.webp'

import { Service } from './components'

import { useSolutions } from './useSolutions'

import styles from './Solutions.module.scss'

const ServicesModal = dynamic(() =>
  import('@/components/ServicesModal/ServicesModal').then(
    (mod) => mod.ServicesModal
  )
)

const SolutionImg = () => {
  return (
    <div className={styles.solutions__img}>
      <Image
        src={PortalImg}
        alt="imagem do portal Equifax BoaVista no computador e celular"
        style={{
          width: '100%',
          height: 'auto'
        }}
      />
    </div>
  )
}

export const Solutions = ({ variant = 'postpaid', title, tagText }) => {
  const {
    filters,
    activeFilterIndex,
    visibleDetailsModal,
    visibleService,
    isMobile,
    isServiceAnimated,
    onClickAction,
    onSelectFilter,
    setVisibleDetailsModal
  } = useSolutions({ variant })

  const { onClickItem } = useClickWithScroll()

  return (
    <section id="solucoes-empresa" className={styles.solutions}>
      <Container>
        <Tag text={tagText} bgColor="white" />

        <Title text={title} customClass={styles.solutions__title} />

        <div className={styles.solutions__wrapper}>
          <div>
            <Filters
              section="solutions"
              filters={filters}
              activeFilterIndex={activeFilterIndex}
              setActiveFilterIndex={(index) => onSelectFilter(index, true)}
            />

            {!isMobile && variant === 'postpaid' && (
              <>
                <SolutionImg />

                <div className={styles.solutions__buttons}>
                  <Button
                    variant="primary"
                    id="solutions-wantToHire-button"
                    onClick={() => onClickItem('proposal', 4)}
                  >
                    Quero contratar
                  </Button>

                  <ButtonWhatsapp
                    variant="secondary"
                    id="solutions-whatsapp-button"
                  >
                    Contrate pelo WhatsApp
                  </ButtonWhatsapp>
                </div>
              </>
            )}
          </div>

          <Service
            variant={variant}
            visibleService={visibleService}
            isAnimated={isServiceAnimated}
            onClickAction={onClickAction}
          />

          {isMobile && variant === 'postpaid' && <SolutionImg />}
        </div>

        {visibleDetailsModal && (
          <ServicesModal
            isVisible={true}
            type={visibleDetailsModal}
            closeModal={() => setVisibleDetailsModal(null)}
          />
        )}
      </Container>
    </section>
  )
}
