/* eslint-disable react/jsx-key */

import { Fragment } from 'react'

import { BLUEBOX_URL } from '@/constants/links'

import { Details } from './components'

const PROSPECTING_CONSULTATION_DETAILS = [
  {
    icon: 'equifax-megaphone',
    text: 'Segmentações personalizadas para campanhas de marketing.'
  },
  {
    icon: 'equifax-users-plus',
    text: 'Encontre os clientes ideais para o seu negócio.'
  },
  {
    icon: 'equifax-target',
    text: 'Oferte seus produtos e serviços para quem realmente pretende comprar.'
  },
  {
    icon: 'equifax-cube',
    text: (
      <>
        BlueBox:{' '}
        <a rel="noreferrer" id="solution-bluebox-link" href={BLUEBOX_URL}>
          <b>Saiba mais</b>
        </a>
        .
      </>
    )
  }
]

const CNPJ_CONSULTATION_DETAILS = [
  { text: 'Score' },
  { text: 'Dados cadastrais' },
  { text: 'Dados restritivos' },
  { text: 'Indicadores positivos' },
  { text: 'Quadro societário' },
  { text: 'Limite de crédito' }
]

const CPF_CONSULTATION_DETAILS = [
  { text: 'Score' },
  { text: 'Dados cadastrais' },
  { text: 'Dados restritivos' },
  { text: 'Renda presumida' },
  { text: 'Indicador de decisão' },
  { text: 'Limite de parcela sugerida' }
]

const RECOVERY_CONSULTATION_DETAILS = [
  {
    icon: 'sending-mail',
    text: 'Comunicações automatizadas, via e-mail e SMS.'
  },
  { icon: 'equifax-graphics', text: 'Maior recuperação de dívidas.' },
  { icon: 'equifax-money-check', text: 'Prejuízos cada vez menores.' }
]

const INTERCONNECT_DETAILS = [
  {
    title: 'Aumento de eficiência',
    text: 'Tome decisões de crédito mais rápidas, identificando clientes de alto risco'
  },
  {
    title: 'Redução de custos',
    text: 'Diminua despesas com análises manuais e relatórios extensos.'
  },
  {
    title: 'Tecnologia global em nuvem',
    text: 'Ideal para empresas que não possuem departamento de análise de crédito, plataforma ajustável ao tamanho do seu negócio.'
  },
  {
    title: 'Segurança',
    text: 'Segurança de dados em conformidade com as regulamentações.'
  },
  {
    title: 'Checagem simples',
    text: 'Verificação em tempo real, com sugestões claras e objetivas.'
  }
]

const MANAGEMENT_CONSULTATION_DETAILS = [
  {
    icon: 'equifax-user-analysis',
    text: 'Análise do comportamento financeiro dos seus clientes.'
  },
  {
    icon: 'equifax-graphic-analysis',
    text: 'Avaliação do risco de inadimplência.'
  }
]

const ANTIFRAUD_CONSULTATION_DETAILS = [
  {
    icon: 'equifax-robot',

    text: 'Inteligência Artificial e comportamento de navegação para criar o modelo perfeito de análise de risco para o seu negócio.'
  },
  {
    icon: 'equifax-shopping-bag',
    text: 'Validação rápida de pedidos nas compras online.'
  },
  { icon: 'equifax-shield-2', text: 'Proteção máxima para o seu e-commerce.' }
]

export const POSTPAID_FILTERS = [
  { icon: 'equifax-building-search', title: 'Consulta de CNPJ' },
  { icon: 'equifax-user-search', title: 'Consulta de CPF' },
  { icon: 'equifax-user-check', title: 'Recuperação de clientes' },
  { icon: 'equifax-box', title: 'InterConnect Box' }
]

export const HOME_FILTERS = [
  { icon: 'equifax-users-plus', title: 'Prospecção e Aquisição' },
  { icon: 'equifax-building-search', title: 'Consulta de CNPJ' },
  { icon: 'equifax-user-search', title: 'Consulta de CPF' },
  { icon: 'equifax-hand-check', title: 'Gestão de carteira' },
  { icon: 'equifax-user-check', title: 'Cobrança e Recuperação' },
  { icon: 'equifax-shield-3', title: 'Identificação e Antifraude' }
]

export const POSTPAID_SERVICES = [
  {
    title: 'Consulta de CNPJ',
    actions_type: 'define',
    highlights: [
      <Fragment>
        <b>Define Risco</b> - Relatório Básico
      </Fragment>,
      <Fragment>
        <b>Define Negócio</b> - Relatório Intermediário
      </Fragment>,
      <Fragment>
        <b>Define Limite</b> - Relatório Completo
      </Fragment>
    ],
    description:
      'As consultas de CNPJ utilizam dados restritivos, positivos e analíticos para apoiar suas decisões de crédito empresarial.',
    details: [
      {
        title:
          'Veja algumas das informações de CNPJ disponíveis nos relatórios da Família Define.',
        content: (
          <>
            <Details.Consultation details={CNPJ_CONSULTATION_DETAILS} />
            <p>entre outros.</p>
          </>
        )
      }
    ]
  },
  {
    title: 'Consulta de CPF',
    actions_type: 'acerta',
    highlights: [
      <Fragment>
        <b>Acerta Essencial</b> - Relatório Básico
      </Fragment>,
      <Fragment>
        <b>Acerta Mais</b> - Relatório Intermediário
      </Fragment>,
      <Fragment>
        <b>Acerta Completo</b> - Relatório Completo
      </Fragment>
    ],
    description: (
      <p>
        Visão integrada para <b>decisão de crédito</b>, com recomendação de
        aprovação e sugestão de limite mensal.
      </p>
    ),
    details: [
      {
        title:
          'Veja algumas das informações de CPF disponíveis nos relatórios da Família Acerta.',
        content: (
          <>
            <Details.Consultation details={CPF_CONSULTATION_DETAILS} />
            <p>entre outros.</p>
          </>
        )
      }
    ]
  },
  {
    title: 'Recuperação de clientes',
    highlights: [<b>Notificação</b>, <b>Negativação</b>],
    description: (
      <p>
        Aumentamos significativamente suas chances de{' '}
        <b>recuperação de dívidas</b> com nossas soluções e serviços no{' '}
        <b>plano Pós-pago</b>.
      </p>
    ),
    details: [
      {
        icon: 'equifax-contract',
        title: 'Notificação',
        content: (
          <p>
            Informe seus clientes sobre dívidas e pagamentos em atraso e
            estimule a negociação antes da inclusão no Cadastro de devedores.
          </p>
        )
      },
      {
        icon: 'equifax-contract-error',
        title: 'Negativação',
        content: (
          <p>
            É o registro de uma dívida no SCPC, vinculado ao CPF ou CNPJ do
            devedor, com envio de comunicado. Permite que as empresas consultem
            essa informação para avaliar riscos e possibilidades de ofertar
            crédito
          </p>
        )
      }
    ]
  },
  {
    title: 'InterConnect Box',
    description: (
      <p>
        A solução ideal para pequenas e médias empresas que{' '}
        <b>buscam análise rápida, segura e econômica</b> para aprovação de
        crédito.
      </p>
    ),
    details: [
      {
        content: <Details.Interconnect details={INTERCONNECT_DETAILS} />
      }
    ]
  }
]

export const HOME_SERVICES = [
  {
    title: 'Prospecção e Aquisição',
    description:
      'Ajudamos sua empresa a descobrir novos clientes para ampliar suas oportunidades de negócio.',
    details: [
      {
        content: (
          <>
            <Details.Consultation details={PROSPECTING_CONSULTATION_DETAILS} />
          </>
        )
      }
    ]
  },
  {
    title: 'Consulta de CNPJ',
    actions_type: 'define',
    highlights: [
      <Fragment>
        <b>Define Risco</b> - Relatório Básico
      </Fragment>,
      <Fragment>
        <b>Define Negócio</b> - Relatório Intermediário
      </Fragment>,
      <Fragment>
        <b>Define Limite</b> - Relatório Completo
      </Fragment>
    ],
    description:
      'As consultas de CNPJ utilizam dados restritivos, positivos e analíticos para apoiar suas decisões de crédito empresarial.',
    details: []
  },
  {
    title: 'Consulta de CPF',
    actions_type: 'acerta',
    highlights: [
      <Fragment>
        <b>Acerta Essencial</b> - Relatório Básico
      </Fragment>,
      <Fragment>
        <b>Acerta Mais</b> - Relatório Intermediário
      </Fragment>,
      <Fragment>
        <b>Acerta Completo</b> - Relatório Completo
      </Fragment>
    ],
    description: (
      <p>
        Visão integrada para <b>decisão de crédito</b>, com recomendação de
        aprovação e sugestão de limite mensal.
      </p>
    ),
    details: []
  },
  {
    title: 'Gestão de carteira',
    description:
      'Fornecemos os dados certos e garantimos análises detalhadas para que você tome sempre as melhores decisões, de acordo com os seus objetivos de negócio.',
    details: [
      {
        content: (
          <>
            <Details.Consultation details={MANAGEMENT_CONSULTATION_DETAILS} />
          </>
        )
      }
    ]
  },
  {
    title: 'Cobrança e Recuperação',
    description:
      'Notifique clientes que estão devendo ou com pagamento em atraso e melhore suas chances de recuperar essa dívida. Ao incluir um devedor no banco de dados do SCPC, o registro pode ser consultado por outras empresas que poderão reconsiderar oferecer crédito para quem pode não ter condições ou intenção de pagar.',
    details: [
      {
        content: (
          <>
            <Details.Consultation details={RECOVERY_CONSULTATION_DETAILS} />
          </>
        )
      }
    ]
  },
  {
    title: 'Identificação e Antifraude',
    description:
      'Utilizamos dados para tomadas de decisão precisas, combatendo a fraude em cada etapa do processo: Onboarding, Transacional e Chargeback',
    details: [
      {
        content: (
          <>
            <Details.Consultation details={ANTIFRAUD_CONSULTATION_DETAILS} />
          </>
        )
      }
    ]
  }
]
