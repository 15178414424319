import { useEffect, useRef, useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'
import { usePlans } from '@/hooks/usePlans'

export const usePlansSection = () => {
  const [plans, setPlans] = useState([])
  const [expandedPlans, setExpandedPlans] = useState({})
  const [visibleElementIndex, setVisibleElementIndex] = useState(1)
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)

  const listRef = useRef()
  const itemsRef = useRef([])

  const { renderedContentType } = useConditionalRendering()
  const { postpaidPlans } = usePlans()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  useEffect(() => {
    if (renderedContentType) {
      setPlans(postpaidPlans)
    }
  }, [renderedContentType])

  useEffect(() => {
    if (plans.length > 0) {
      const multiply = isMobile ? 2 : 1

      scrollList('right', multiply)
    }
  }, [plans.length])

  const scrollList = (direction, multilpy = 1) => {
    const { width } = listRef.current.getBoundingClientRect()

    const scrollSize = isMobile ? width : width / 5 - 40
    const left = direction === 'left' ? -scrollSize : scrollSize

    requestAnimationFrame(() => {
      listRef.current.scrollBy({ left: left * multilpy })
    })
  }

  const isOnScreen = (element) => {
    const { x } = element.getBoundingClientRect()

    return x > 0 && x < 80
  }

  const onScroll = () => {
    const newVisibleCardIndex =
      itemsRef.current.findIndex((card) => isOnScreen(card)) ?? 0

    if (
      isMobile &&
      newVisibleCardIndex >= 0 &&
      newVisibleCardIndex !== visibleElementIndex
    ) {
      setVisibleElementIndex(newVisibleCardIndex)
    }
  }

  const onClickController = (direction) => {
    scrollList(direction)

    if (isMobile) return

    setVisibleElementIndex((prev) => {
      return direction === 'left' ? prev - 1 : prev + 1
    })
  }

  return {
    plans,
    listRef,
    itemsRef,
    postpaidPlans,
    expandedPlans,
    visibleElementIndex,
    isMobile,
    isPaymentDetailsOpen,
    renderedContentType,
    setExpandedPlans,
    setIsPaymentDetailsOpen,
    onScroll,
    onClickController
  }
}
